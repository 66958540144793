import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimpleLineIcon from "react-simple-line-icons";
import Pool from "../assets/images/Pool.jpg";
import Museum from "../assets/images/Museum.jpg";
// import Florø from "../assets/images/Florø.jpg";
import Oya from "../assets/images/Oya.jpg";
import Svanoy from "../assets/images/Svanoy.jpg";
import Kinn from "../assets/images/Kinn.jpg";
import Brandsoya from "../assets/images/Brandsoya.jpg";
import Folkepark from "../assets/images/folkepark.jpg";

function ImageSlider() {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Museum} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb">Kystmuseet i Sogn og Fjordane </h2>
            <p className="description">
              Om du vil oppleve lukta av gamle båtar og kystkultur er Kystmuseet
              noko for deg. Her kan oppdage lokale båttypar frå ei svunnen tid
              og
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href=" https://www.fjordnorway.com/no/se-og-gjore/kystmuseet-i-sogn-og-fjordane"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Oya} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb"> Øya Kinn </h2>
            <p className="description">
              Øya Kinn Med flott turterreng og lett tilkomst med rutebåt frå
              Florø er Kinn eit populært turmål heile året.Heilt ute i havgapet
              ligg den irrgrøne, grasdekte øya Kinn, med Kinnakyrkja og
              Kinnaklova.
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/oya-kinn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Brandsoya} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb">Brandsøyåsen </h2>
            <p className="description">
              Frå Brandsøyåsen, 293 meter over havet, vil du ha storslått utsikt
              over over Florø by, fjell, fjord og den vakre skjergarden i vest.
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/brandsoyasen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Florø} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb">Rundtur frå Florø</h2>
            <p className="description">
              Ta rutebåten i Florø-skjergarden og bli med på ein fantastisk
              rundtur mellom holmar og skjer.Få ei magisk oppleving med
              kveldsruta!
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/rundtur-med-rutebat-fra-floro"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Svanoy} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb"> Tur på Svanøy</h2>
            <p className="description">
              Opplev historiske Svanøy!Her får du guida omvising på historiske
              Svanøy Hovedgård, og besøke Norsk Hjortesenter, der du får oppleve
              hjorten på nært hald.
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/guida-tur-pa-svanoy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Pool} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb">Havhesten</h2>
            <p className="description">
              10 minutts vandring frå Florø sentrum finn du badeanlegget
              Havhesten - ideelt plassert ved sjøen med utsikt mot både fjord og
              fjell.
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/havhesten"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Kinn} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb">Omvising og ølsmaking</h2>
            <p className="description">
              Bli med på omvising og ølsmaking på Vesle Kinn! Vesle Kinn er både
              ølkaféen og testbryggeriet til Kinn Bryggeri.
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/kinn-bryggeri-omvising-og-olsmaking-pa-vesle-kinn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Folkepark} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb">Sørstrand Folkepark</h2>
            <p className="description">
              Sørstrand Folkepark er eit friluftsområde som ligg to kilometer
              frå Florø sentrum, rett i austenden av flyplassen.
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/sorstrand-folkepark"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-wrapper-activities">
        <div className="card-activities">
          <div className="card-image">
            <img src={Brandsoya} alt="" />
          </div>
          <div className="details-activites">
            <h2 className="tablet-font mb">Brandsøyåsen </h2>
            <p className="description">
              Frå Brandsøyåsen, 293 meter over havet, vil du ha storslått utsikt
              over over Florø by, fjell, fjord og den vakre skjergarden i vest.
            </p>
            <div className="restaurant-button flex" type="button">
              <div className="arrow-right">
                {" "}
                <i>
                  <SimpleLineIcon name="arrow-right" size="small" />
                </i>
              </div>
              <div className="explore">
                <a
                  className="leg-till"
                  href="https://www.fjordnorway.com/no/se-og-gjore/brandsoyasen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Les mer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
}

export default ImageSlider;
