import React, { useState, useLayoutEffect, useRef } from "react";
import Hero from "../components/hero";
import Bry from "../assets/images/Bry.jpg";
import Dap from "../assets/images/Dap.jpg";
import Kon from "../assets/images/Kon.jpg";
import Mine from "../assets/images/Mine.jpg";
import SimpleLineIcon from "react-simple-line-icons";
import arrengment from "../Video/arrengment.mp4";
import "../pages/styles/Arrengment.scss";

// const arrengment = lazy(() => import("../Video/arrengment.mp4"));

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
function Arrengment() {
  return (
    <>
      <Hero hero="arrengmentHero">
        <div className="overlay-hero"></div>

        <div className="call-to-action">
          <div className="text-center">
            <h1 className="fade-up inner-heading padding">
              <span className="curved-2">SELSKAP &</span>
              <span className="curved-2"> ARRANGEMENT</span>
            </h1>
          </div>
        </div>
      </Hero>

      <section className="event-area fix section-padding">
        <div className="container">
          <div className="d-flex extra-margin">
            <div className="intro-arrengment-left">
              <FadeInSection>
                <div className="eyebrow">
                  <div
                    className="eyebrow__number eyebrow__item"
                    data-animation="number"
                  >
                    <span>01</span>
                  </div>
                  <div
                    className="eyebrow__line eyebrow__item"
                    data-animation="line"
                  ></div>
                  <div
                    className="eyebrow__text eyebrow__item"
                    data-animation="text"
                  >
                    <h5>Arrangement</h5>
                  </div>
                </div>
              </FadeInSection>

              <FadeInSection>
                <h2 data-split-text="true" aria-label="">
                  <span className="word1" aria-hidden="true"></span>{" "}
                </h2>
                <h2>Selskap & Events</h2>
              </FadeInSection>
              <FadeInSection>
                <div className="editable pr ">
                  <p>
                    Skal du arrangere selskap? Hos oss på Victoria Hotell sørger
                    vi for en flott og gjennomført ramme for ditt arrangement.
                  </p>
                  <br />

                  <p>
                    Vi har flere lokaler av ulik størrelse som passer til både
                    dåp, konfirmasjon og bryllup. Våre medarbeidere har lang
                    erfaring med selskap og vil hjelpe dere med å skreddersy
                    ditt arrangement slik at dagen blir helt spesiell.
                  </p>
                  <br />
                  <p>
                    Ta kontakt med oss for priser og tilpassing av ditt
                    arrangement.
                  </p>
                </div>

                <a
                  className="black-links"
                  href="mailto:co.floro@strawberry.no?subject=Selskap og arrengement"
                >
                  <div className="arrow-right-bolette">
                    <div>
                      {" "}
                      <i>
                        <SimpleLineIcon name="arrow-right mr" size="small" />
                      </i>
                    </div>
                    KONTAKT OSS
                  </div>{" "}
                </a>
              </FadeInSection>
            </div>

            <div className="intro-arrengment-right float-right">
              <video
                className="video-arrengment"
                controls
                loop
                muted
                src={arrengment}
                type="video/mp4"
                // class="lazy"
              ></video>
            </div>
          </div>

          {/*----------------- EVENT CARD----------------- */}
          {/* konfirmations */}
          <div className="smaller_section_padding extra-margin mt-2">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <div>
                  <FadeInSection>
                    <img
                      className="ev-1 "
                      src={Kon}
                      alt="Konfirmasjon på komforthotell Viktoria i Floro "
                    />
                  </FadeInSection>
                </div>
              </div>

              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className="event-caption">
                    <h2 className="event-title mb"> Konfirmasjon</h2>
                    <p className="justify">
                      Konfirmasjon er en stor dag for både hovedpersonen,
                      familie og venner. På Victoria Hotell legger vi alt til
                      rette for en minnerik og fin dag. Dere vil komme til
                      dekket bord med hvite duker, lys og tøyservietter. Maten
                      er en viktig del av feiringen, og vi har satt sammen ulike
                      menyer dere kan velge fra.
                    </p>
                    <a
                      className="black-links"
                      href="mailto:co.floro@strawberry.no?subject=Konfirmasjon"
                    >
                      <button className="book-now  move-button">
                        {/* <div className="arrow-right-events">
                          {" "}
                          <i>
                            <SimpleLineIcon name="arrow-right" size="small" />
                          </i>
                        </div> */}
                        KONTAKT OSS
                      </button>{" "}
                    </a>
                  </div>
                </FadeInSection>
              </div>
            </div>
          </div>

          {/* weddings */}
          <div className="smaller_section_padding extra-margin">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className=" event-caption event-caption-2">
                    <h2 className="event-title mb"> Bryllup</h2>
                    <p className="justify">
                      Bryllup er en av de største dagene i livet og vi synes det
                      er en ære å få være med og gjøre dagen deres akkurat slik
                      dere drømmer om. Vi organiserer alt fra velkomstdrinker,
                      servering, gavebord, oppdekking, kakebord m.m. Vi har
                      store og nyoppusede hotellrom der vi kan servere frokost
                      på rommet og sen utsjekk.
                    </p>
                    <a
                      className="black-links"
                      href="mailto:co.floro@strawberry.no?subject=Bryllup"
                    >
                      <button className="book-now ">
                        {/* <div className="arrow-right-events">
                          {" "}
                          <i>
                            <SimpleLineIcon name="arrow-right" size="small" />
                          </i>
                        </div> */}
                        KONTAKT OSS
                      </button>{" "}
                    </a>
                  </div>
                </FadeInSection>
              </div>

              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <div>
                  <img
                    width="700"
                    height="400"
                    className="event-image-1"
                    src={Bry}
                    alt="Bryllaup på komforthotell Viktoria i Floro "
                  />
                </div>
              </div>
            </div>
          </div>
          {/* christning */}
          <div className="smaller_section_padding extra-margin">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <div>
                  <FadeInSection>
                    <img
                      className="ev-1-0"
                      src={Dap}
                      alt="Dap på komforthotell Viktoria i Floro "
                    />
                  </FadeInSection>
                </div>
              </div>

              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className="event-caption">
                    <h2 className="event-title mb"> Dåp</h2>
                    <p className="justify">
                      En viktig merkedag i familien og en fin anledning til å
                      samle venner og familie. Vi tar oss av alt det praktiske,
                      slik at dere bare kan hygge dere sammen med alle de dere
                      er glade i.
                    </p>
                    <a
                      className="black-links"
                      href="mailto:co.floro@strawberry.no?subject=Dåp"
                    >
                      <button className="book-now">
                        {/* <div className="arrow-right-events">
                          {" "}
                          <i>
                            <SimpleLineIcon name="arrow-right" size="small" />
                          </i>
                        </div> */}
                        KONTAKT OSS
                      </button>{" "}
                    </a>
                  </div>
                </FadeInSection>
              </div>
            </div>
          </div>
          {/* funerals */}
          <div className="smaller_section_padding">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className="event-caption event-caption-2 bottom-margin ">
                    <h2 className="event-title mb"> Minnestund </h2>
                    <p className="justify">
                      Minnestund er en viktig markering på en dag med mye
                      følelser. Vi tilbyr en varm og rolig atmosfære i skjermede
                      lokaler. Bordet blir dekket høytidelig opp med hvite
                      duker, lys og tøyservietter. Blomster og eventuell pynt må
                      bestilles selv, men vi setter dette ut for dere slik dere
                      ønsker.
                    </p>
                    <a
                      className="black-links"
                      href="mailto:co.floro@strawberry.no?subject=Minnestund"
                    >
                      <button className="book-now">
                        {/* <div className="arrow-right-events">
                          {" "}
                          <i>
                            <SimpleLineIcon name="arrow-right" size="small" />
                          </i>
                        </div> */}
                        KONTAKT OSS
                      </button>{" "}
                    </a>
                  </div>
                </FadeInSection>
              </div>

              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <div>
                  <img
                    // className="ev-1"
                    className="event-image-1 mine"
                    src={Mine}
                    alt="Minnestund på komforthotell Viktoria i Floro "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="room-parallax-image-konferanse">
          <div className="parallax-image_bg-arrengment">
            <div className="overlay-hero-arrengment "> </div>
            <div className="contact-content-rom">
              Hvis du har <br></br> spørsmål, <br></br> ring oss gjerne!
              <div className="bolette-number">Tel: 57 75 25 55</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Arrengment;
