import React from "react";
import "../pages/styles/Contact.scss";
import Map from "../components/map";
import Hero from "../components/hero";

import "../App.scss";

const Contact = () => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");

  // const handleSubmit = (e) => {
  //   fetch("https://formsubmit.co/ajax/8e7ba5c2c021487d304b7ff528ddb3d6", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify({
  //       name: name,
  //       email: email,
  //       message: message,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => console.log(data))
  //     .catch((error) => console.log(error));
  //   e.preventDefault();
  //   setName("");
  //   setEmail("");
  //   setMessage("");
  // };
  return (
    <>
      <Hero hero="contactHero"></Hero>
      <section className="contact-section">
        <div className="container-small d-flex">
          <div className="row contact-width">
            <div className="col-lg-8">
              <div className="col-12">
                <h2 className="contact-title">Kontaktinformasjon</h2>
              </div>
              <div className="contact-black">
                <ul className="contact__info ">
                  <li className="contact-black">
                    <strong>Telefon:</strong> +47 57 75 25 55
                  </li>
                  <li className="contact-black">
                    <strong>Adresse:</strong> Markegata 43, 6900 Florø, Norge
                  </li>

                  <a
                    className="contact-black"
                    href="mailto:co.floro@strawberry.no?subject=Kontakt"
                  >
                    <li>
                      <strong> E-post:&nbsp;</strong>

                      <span className="mail-link">co.floro@strawberry.no</span>
                    </li>
                  </a>
                </ul>
              </div>
              {/* <div className="col"> 
                <div className="contact-container">
                  <form
                    className="contact-form"
                    action=" 8e7ba5c2c021487d304b7ff528ddb3d6 "
                    method="POST"
                    onSubmit={handleSubmit}
                  >
                    <input
                      className="name"
                      type="text"
                      value={name}
                      id="name"
                      name="name"
                      placeholder="NAME*"
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <input
                      className="email"
                      type="email"
                      value={email}
                      id="email"
                      name="email"
                      placeholder="EMAIL*"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />

                    <textarea
                      className="message"
                      id="message"
                      value={message}
                      name="message"
                      placeholder="COMMENT"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <button
                      className="book-now contact-btn"
                      type="submit"
                      value="Submit"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
              {/*  */}
            </div>

            <div className="col-lg-7">
              <Map />
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------------------------------- */}
    </>
  );
};

export default Contact;
