import React, { Component } from "react";
import strawberry from "../assets/images/Strawberry.svg";
import white from "../assets/images/white.png";
import trip from "../assets/images/tripadviser.jpg";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/backToTop";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import GetFullYear from "../components/Year";

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

// const goToTopMore = () => {
//   window.scrollTo({
//     top: 85,
//     behavior: "smooth",
//   });
// };

export default class Footer extends Component {
  render() {
    return (
      <div className="footer-wrapper flex">
        <div className="container-small footer-padding ">
          <div className="footer-logo-padding">
            <img className="footer-logo" src={white} alt="" />
          </div>
          <div className="row-1 justify-content-between">
            <div className="col-m1">
              {" "}
              <div className="contact__info">
                <ul className="contact__info">
                  <li>Adresse: Markegata 43, 6900 Florø, Norge</li>

                  <li>Telefon: 47 57 75 25 55</li>
                  <li>
                    {" "}
                    E-post:&nbsp;
                    <a href="mailto:co.floro@strawberry.no?">
                      {" "}
                      <span className="underline">co.floro@strawberry.no</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="socials">
                <a
                  className="info-white"
                  href="https://www.facebook.com/comforthotelfloro"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook className="social" color="#818796" size={30} />
                </a>
                <a
                  className="info-white"
                  href="https://www.instagram.com/comfort_floro/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram color="#818796" size={30} />
                </a>
              </div>
            </div>

            <div className=" col-m2 ">
              <div className="footer-links row-3 ">
                <div className="menu-footer">
                  <ul className="inner-li">
                    <li>
                      <Link
                        // activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/"
                      >
                        OM OSS
                      </Link>
                    </li>

                    <li>
                      <Link
                        // activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/rom"
                      >
                        ROM
                      </Link>
                    </li>

                    <li>
                      <Link
                        // activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/arrangement"
                      >
                        ARRANGEMENT
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="menu-footer ">
                  <ul className="inner-li">
                    <li>
                      <Link
                        activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/konferanse"
                      >
                        KONFERANSE
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/restaurant"
                      >
                        RESTAURANT
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/vinkjeller"
                      >
                        VINKJELLER
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* <div className="menu-footer">
                  <div className="row-2">
                    <ul className="inner-li">
                      <li>
                        <Link
                          activeclassname="active"
                          className="nav_links-footer"
                          to="/gavekort"
                        >
                          GÅVEKORT
                        </Link>
                      </li>
                   
                    </ul>
                  </div>
                </div> */}

                <div className="menu-footer ">
                  <ul className="inner-li">
                    <li>
                      <Link
                        activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/kontakt"
                      >
                        KONTAKT
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/personvern"
                      >
                        PERSONVERN
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeclassname="active"
                        className="nav_links-footer"
                        onClick={goToTop}
                        to="/vilkar"
                      >
                        VILKÅR OG BETINGELSER
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 
            <div className="col-m2">
              <div className="row-3">
              <ul className="inner-li">
                <li>
                  <Link
                    activeclassname="active"
                    className="nav_links-footer"
                    onClick={goToTop}
                    to="/kontakt"
                  >
                    KONTAKT
                  </Link>
                </li>
                <li>
                  <Link
                    activeclassname="active"
                    className="nav_links-footer"
                    onClick={goToTopMore}
                    to="/personvern"
                  >
                    PERSONVERN
                  </Link>
                </li>
                <li>
                  <Link
                    activeclassname="active"
                    className="nav_links-footer"
                    onClick={goToTopMore}
                    to="/vilkar"
                  >
                    VILKÅR OG BETINGELSER
                  </Link>
                </li>
              </ul>
              </div>
            </div> */}
          </div>

          <div className="footer-bottom-area">
            <div className="footer-border"></div>
            <div className="justify-content-between review-links">
              <div className=" col-m3 reviews">
                <a
                  href="https://www.nordicchoicehotels.no/?gclid=Cj0KCQjw166aBhDEARIsAMEyZh4iylntlrpo3spsvzDoTxLU_6FolgwqhytfGjZOJ78OGZmTI3DYMOYaAjwtEALw_wcB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="review-img" src={strawberry} alt="" />
                </a>
              </div>

              <div className="align-items-center copy">
                <span>Copyright@</span> <GetFullYear />{" "}
                <span> .All rights reserved</span>
              </div>

              <div className=" col-m3 reviews  ">
                <a
                  href="https://www.tripadvisor.com/Hotel_Review-g190508-d1396544-Reviews-Comfort_Hotel_Floro-Floro_Flora_Municipality_Sogn_og_Fjordane_Western_Norway.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="review-img" src={trip} alt="" />
                </a>
              </div>
              <ScrollToTop />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
