import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import helmers from "../assets/images/helmers.jpg";
import Img5 from "../assets/images/Img5.jpg";
import victoria from "../assets/images/victoria.jpeg";
import SimpleLineIcon from "react-simple-line-icons";
import Video from "../components/video";
import Hero from "../components/hero";
import ImageSlider from "../components/Slider";
import Activities from "../components/activites";
import "../pages/styles/About.scss";
import "../App.scss";

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

function About() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* hero  */}
      <Hero hero="aboutHero">
        <Video />
      </Hero>

      {/* about intro */}
      <section className=" container  layers two-halves bg-gray two-halves_l">
        <div className="grid-container two-halves_bg layers_center ">
          <div className="grid-x grid-margin-x ">
            <div className="cell large-7 large-offset-5 ">
              <div className="two-halves__img parallax-image ">
                <div className="image-container parallax-image__item">
                  <img
                    className="parallax-image__item victoria-img"
                    src={victoria}
                    alt="Inngang til Victoria Hotel Florø."
                    style={{
                      transform: `translateY(${offset * 0.1}px)`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FadeInSection>
          <div className="grid-container layers__center two-halves__overlay">
            <div className="grid-x grid-margin-x">
              <div className="cell large-6 large-offset-1 ">
                <div className="two-halves__content">
                  <div className="grid-x grid-margin-x">
                    <div className="cell large-8 large-offset-2 victoria">
                      <div className="eyebrow">
                        <div
                          className="eyebrow__number eyebrow__item"
                          data-animation="number"
                        >
                          <span>01</span>
                        </div>
                        <div
                          className="eyebrow__line eyebrow__item"
                          data-animation="line"
                        ></div>
                        <div
                          className="eyebrow__text eyebrow__item"
                          data-animation="text"
                        >
                          <h5>Velkommen</h5>
                        </div>
                      </div>

                      {/* <FadeInSection> */}
                      <h2 data-split-text="true" aria-label="">
                        <span className="word1" aria-hidden="true"></span>{" "}
                      </h2>
                      <h2> Victoria Hotel</h2>
                      {/* </FadeInSection> */}
                      {/* <FadeInSection> */}
                      <div className="editable">
                        <p></p>
                        <p>
                          Comfort Hotel Victoria Florø er et moderne hotell i
                          hjertet av Florø, Norges vestligste by. Hotellet
                          ligger midt i sentrum med gangavstand til byens
                          fasiliteter. Hotellet ble pusset opp i 2019 og er
                          innredet med designmøbler og moderne farger. Her kan
                          du ta deg et glass vin, jobbe eller surfe med gratis
                          wifi.
                        </p>
                        <p></p>
                      </div>
                      {/* </FadeInSection> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>
      </section>

      {/* rooms section */}
      <section className="section-two">
        <div className="room-section">
          <FadeInSection>
            <div className="rom-content flex-rooms">
              <div>
                <div className="eyebrow">
                  <div
                    className="eyebrow__number eyebrow__item"
                    data-animation="number"
                  >
                    <span>02</span>
                  </div>
                  <div
                    className="eyebrow__line eyebrow__item"
                    data-animation="line"
                  ></div>
                  <div
                    className="eyebrow__text eyebrow__item"
                    data-animation="text"
                  >
                    <h5>Utforske</h5>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="room-padding">Rom</h2>
              </div>
              <div className=" editable-text_center">
                <p></p>
                <p>
                  På våre hotellrom finner du en stor flatskjerm-tv, gratis wifi
                  og en komfortabel seng. Vi kjører Comfort Style, prisgunstige
                  rom med moderne design. Ble lørdagskvelden litt ekstra lang,
                  så trenger du ikke stresse. På søndager trenger du ikke sjekke
                  ut før klokka 18.00! Om du liker en treningsøkt, så
                  samarbeider vi med treningsstudioet Spenst, som ligger tre
                  minutter fra hotellet. Adgangskort får du i resepsjonen.
                </p>
                <p></p>
              </div>

              <div className="restaurant-button flex" type="button">
                <div className="arrow-right">
                  {" "}
                  <i>
                    <SimpleLineIcon name="arrow-right" size="small" />
                  </i>
                </div>
                <div className="explore">
                  <Link
                    activeclassname="active"
                    className="nav_links "
                    onClick={goToTop}
                    to="/rom"
                  >
                    <span className="leg-till">Les mer</span>
                  </Link>
                </div>
              </div>
            </div>
          </FadeInSection>
        </div>
      </section>

      {/* restaurant section */}
      <section className="container section-three layers two-halves bg-gray two-halves_l  ">
        <div className="grid-container two-halves_bg layers_center ">
          <div className="grid-x grid-margin-x ">
            <div className="cell large-7 large-offset-5-bolete">
              <div className="two-halves__img parallax-image-offset">
                <div className="image-container parallax-image__item bolette-mob">
                  <img
                    className="parallax-image__item"
                    src={Img5}
                    alt="Bolette Helmers Olsen restaurant i hotel Victoria Florø"
                    style={{
                      transform: `translateY(${offset * 0.1}px)`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FadeInSection>
          <div className="grid-container layers__center two-halves__overlay">
            <div className="grid-x grid-margin-x">
              <div className="cell large-6 large-offset-1-bolete">
                <div className="two-halves__content">
                  <div className="grid-x grid-margin-x">
                    <div className="cell large-8 large-offset-2-bolette">
                      <div className="eyebrow">
                        <div
                          className="eyebrow__number eyebrow__item"
                          data-animation="number"
                        >
                          <span>03</span>
                        </div>
                        <div
                          className="eyebrow__line eyebrow__item"
                          data-animation="line"
                        ></div>
                        <div
                          className="eyebrow__text eyebrow__item"
                          data-animation="text"
                        >
                          <h5>Restaurant</h5>
                        </div>
                      </div>

                      {/* <FadeInSection> */}
                      <h2 data-split-text="true" aria-label="">
                        <span className="word1" aria-hidden="true"></span>{" "}
                      </h2>
                      <h2>Bolette</h2>
                      {/* </FadeInSection> */}
                      {/* <FadeInSection> */}
                      <div className="editable">
                        <p></p>
                        <p>
                          Restaurant Bolette har sitt navn fra Bolette Helmers
                          Olsen. Bolette er kvinnen som på slutten av
                          1800-tallet la grunnlaget for hotelldrift i Florø, og
                          som bygde det første hotellbygget på samme sted som
                          Victoria Hotell er i dag.
                        </p>

                        <div className="restaurant-button flex" type="button">
                          <div className="arrow-right">
                            {" "}
                            <i>
                              <SimpleLineIcon name="arrow-right" size="small" />
                            </i>
                          </div>
                          <div className="explore">
                            <Link
                              activeclassname="active"
                              className="nav_links "
                              onClick={goToTop}
                              to="/restaurant"
                            >
                              <span className="leg-till">Les mer</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* </FadeInSection> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>
      </section>

      {/* fru helmers section */}
      <section className=" container layers two-halves bg-gray two-halves_l ">
        <div className="grid-container two-halves_bg layers_center ">
          <div className="grid-x grid-margin-x ">
            <div className="cell large-7 large-offset-5">
              <div className="two-halves__img parallax-image">
                <div className="image-container parallax-image__item">
                  <img
                    className="parallax-image__item helmers"
                    src={helmers}
                    alt="Fru Helmers vinceller, restaurant i hotel Victoria Florø"
                    style={{
                      transform: `translateY(${offset * 0.1}px)`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FadeInSection>
          <div className="grid-container layers__center two-halves__overlay">
            <div className="grid-x grid-margin-x">
              <div className="cell large-6 large-offset-1 helmers-mobile">
                <div className="two-halves__content ">
                  <div className="grid-x grid-margin-x">
                    <div className="cell large-8 large-offset-2 ">
                      <div className="eyebrow">
                        <div
                          className="eyebrow__number eyebrow__item"
                          data-animation="number"
                        >
                          <span>04</span>
                        </div>
                        <div
                          className="eyebrow__line eyebrow__item"
                          data-animation="line"
                        ></div>
                        <div
                          className="eyebrow__text eyebrow__item"
                          data-animation="text"
                        >
                          <h5>Vinkjeller</h5>
                        </div>
                      </div>

                      {/* <FadeInSection> */}
                      <h2 data-split-text="true" aria-label="">
                        <span className="word1" aria-hidden="true"></span>{" "}
                      </h2>
                      <h2> Fru Helmers</h2>
                      {/* </FadeInSection> */}
                      {/* <FadeInSection> */}
                      <div className="editable">
                        <p></p>
                        <p>
                          I en bortgjemt kjeller, som tidligere ble brukt som
                          lintøylager, har vi fått et vinlager og et helt eget
                          selskapslokale. Fru Helmers har også sitt navn fra
                          Bolette Helmers Olsen.
                        </p>
                        <p></p>

                        <div className="restaurant-button flex" type="button">
                          <div className="arrow-right">
                            {" "}
                            <i>
                              <SimpleLineIcon name="arrow-right" size="small" />
                            </i>
                          </div>
                          {/* <div className="explore">
                              <Link
                                activeclassname="active"
                                className="nav_links"
                                onClick={goToTop}
                                to="/vinkjeller"
                              >
                                <span className="leg-till">Les mer</span>
                              </Link>
                            </div> */}
                          <div className="explore">
                            <Link
                              activeclassname="active"
                              className="nav_links "
                              onClick={goToTop}
                              to="/restaurant"
                            >
                              <span className="leg-till">Les mer</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      {/* </FadeInSection> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>
      </section>

      {/* Instagram section */}
      <section className="section-five ">
        <div className="flex-container">
          {/* eyebrow */}
          <div className="eyebrow ">
            <div
              className="eyebrow__number eyebrow__item"
              data-animation="number"
            >
              <span>05</span>
            </div>
            <div
              className="eyebrow__line eyebrow__item"
              data-animation="line"
            ></div>
            <div className="eyebrow__text eyebrow__item" data-animation="text">
              <h5>Instagram</h5>
            </div>
          </div>
          {/* title */}
          <div className="title">
            <h2>finn oss på instagram</h2>
          </div>

          <div className="container-small">
            <ImageSlider />
          </div>
        </div>
      </section>

      {/* CONTACT */}
      <section className="contact">
        <div className="contact-content">
          Hvis du har<br></br> spørsmål<br></br> kontakt oss gjerne!
          <a href="mailto:co.floro@strawberry.no">
            {" "}
            <button>
              <span className="arrow-right-contact">
                <SimpleLineIcon name="arrow-right" size="small" />
              </span>

              <span className="contact-margin ">Kontakt oss</span>
            </button>
          </a>
        </div>
      </section>

      {/* activites section */}
      <section className="activites">
        <div className="container-small">
          <div className="flex-container">
            <div className="eyebrow ">
              <div
                className="eyebrow__number eyebrow__item"
                data-animation="number"
              >
                <span>06</span>
              </div>
              <div
                className="eyebrow__line eyebrow__item"
                data-animation="line"
              ></div>
              <div
                className="eyebrow__text eyebrow__item"
                data-animation="text"
              >
                <h5>Florø </h5>
              </div>
            </div>
            <div className="title">
              <h2>Aktiviteter</h2>
            </div>
          </div>
          <Activities />
        </div>
      </section>
    </>
  );
}

export default About;
