import React, { Suspense } from "react";
// import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.scss";

import About from "./pages/About";
import Room from "./pages/Room";
import Arrangement from "./pages/Arrengment";
import Bolette from "./pages/Bolette";
import Helmers from "./pages/Helmers";
import Kontakt from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Konferanse from "./pages/Konferanse";
// import Giftcard from "./pages/Giftcard";

import Navbar from "./components/navbar";
import Footer from "./components/footer";


// const Navbar = lazy(() => import("./components/navbar"));
// const Footer = lazy(() => import("./components/footer"));

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/rom" element={<Room />} />
          <Route path="/arrangement" element={<Arrangement />} />
          <Route path="/konferanse" element={<Konferanse />} />
          <Route path="/restaurant" element={<Bolette />} />
          <Route path="/vinkjeller" element={<Helmers />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/personvern" element={<Privacy />} />
          <Route path="/vilkar" element={<Terms />} />
          {/* <Route path="/gavekort" element={<Giftcard />} /> */}
        </Routes>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;

