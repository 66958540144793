import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Hero from "../components/hero";
import helmers from "../Video/helmers.mp4";
import hel from "../assets/images/hel.jpeg";
import plate from "../assets/images/plate.svg";
import wine from "../assets/images/wine.svg";
import gluten from "../assets/images/gluten.svg";
import plant from "../assets/images/plant.svg";
import ImageSliderHelmers from "../components/sliderHelmers";
import "../pages/styles/Bollete.scss";

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
function Helmers() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Hero>
        <div>
          <div className="overlay-hero-bolette"></div>
          <video
            className="video-overlay"
            autoPlay
            loop
            muted
            playsInline
            src={helmers}
            type="video/mp4"
          ></video>
        </div>
      </Hero>
      {/* INTRO */}

      <section className=" container layers two-halves bg-gray two-halves_l ">
        <div className="grid-container two-halves_bg layers_center ">
          <div className="grid-x grid-margin-x ">
            <div className="cell large-7 large-offset-5">
              <div className="two-halves__img  helmers-image parallax-image-helmers">
                <div className="image-container ">
                  <img
                    className="parallax-image__item_helmers"
                    src={hel}
                    alt=""
                    style={{
                      transform: `translateY(${offset * 0.1}px)`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FadeInSection>
          <div className="grid-container layers__center two-halves__overlay">
            <div className="grid-x grid-margin-x">
              <div className="cell large-6 large-offset-1 mobile-helmers">
                <div className="two-halves__content">
                  <div className="grid-x grid-margin-x">
                    <div className="cell large-8 large-offset-2 ">
                      {/* <FadeInSection> */}
                      <div className="eyebrow">
                        <div
                          className="eyebrow__number eyebrow__item"
                          data-animation="number"
                        >
                          <span>01</span>
                        </div>
                        <div
                          className="eyebrow__line eyebrow__item"
                          data-animation="line"
                        ></div>
                        <div
                          className="eyebrow__text eyebrow__item"
                          data-animation="text"
                        >
                          <h5>VINKJELLER</h5>
                        </div>
                      </div>

                      <h2 data-split-text="true" aria-label="">
                        <span className="word1" aria-hidden="true"></span>{" "}
                      </h2>
                      <h2>Fru Helmers</h2>
                      {/* </FadeInSection> */}
                      {/* <FadeInSection> */}
                      <div className="editable">
                        <p></p>
                        <p>
                          Fru Helmers er et sted for de helt spesielle
                          anledningene. Vi har mat og drikke i fokus og tilbyr
                          alt fra vinsmakinger, tapas og fine dining med
                          tilhørende vinpakker. Selskapslokalet er helt skjermet
                          og har kapasitet til 20 personer.
                        </p>
                        <p></p>
                        <div className="facilities">
                          <div className="facilities__content">
                            <div className="facilities__item">
                              <div className="facilities-item facilities-item-horizontal">
                                <i className="icon-open-breakfast-dinner">
                                  <img
                                    src={plate}
                                    alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                    width={40}
                                  />
                                </i>

                                <span className="icon-bolette center">
                                  Åpen for middag
                                </span>
                              </div>
                            </div>

                            <div className="facilities__item">
                              <div className="facilities-item facilities-item-horizontal">
                                <i className="icon-open-breakfast-dinner">
                                  {" "}
                                  <img
                                    src={plant}
                                    alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                    width={40}
                                  />
                                </i>

                                <span className="icon-bolette">
                                  Vegansk, vegetarisk alternativer
                                </span>
                              </div>
                            </div>

                            <div className="facilities__item">
                              <div className="facilities-item facilities-item-horizontal">
                                <i className="icon-open-breakfast-dinner">
                                  {" "}
                                  <img
                                    src={gluten}
                                    alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                    width={40}
                                  />
                                </i>

                                <span className="icon-bolette">
                                  Glutenfrie alternativer tilgjengelig
                                </span>
                              </div>
                            </div>

                            <div className="facilities__item">
                              <div className="facilities-item facilities-item-horizontal">
                                <i className="icon-open-breakfast-dinner">
                                  {" "}
                                  <img
                                    src={wine}
                                    alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                    width={40}
                                  />
                                </i>

                                <span className="icon-bolette center">
                                  Vinutvalg
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="facilities-button">
                            <a
                              className="black-links"
                              href="mailto:co.floro@strawberry.no?subject=Fru Helmers"
                            >
                              <button className="book-now facilities-button">
                                KONTAKT OSS
                              </button>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* </FadeInSection> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>
      </section>

      {/* Bar section */}
      {/* <section className="event-area fix section-padding">
        <div className="container">
          <div className="bar-margin-top">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <div className="event-image">
                  <FadeInSection>
                    <img className="ev-1" src={helmers1} alt="" />
                  </FadeInSection>
                </div>
              </div>

              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className="event-caption">
                    <div className="eyebrow mb">
                      <div
                        className="eyebrow__number eyebrow__item"
                        data-animation="number"
                      >
                        <span>02</span>
                      </div>
                      <div
                        className="eyebrow__line eyebrow__item"
                        data-animation="line"
                      ></div>
                      <div
                        className="eyebrow__text eyebrow__item"
                        data-animation="text"
                      >
                        <h5>VINKJELLER</h5>
                      </div>
                    </div>
                    <h1 className="event-title">Tapas</h1>
                    <p>
                      Ta middagen i restauranten eller heng i byens kuleste bar
                      som holder til ved siden av lobbyen på hotellet. Her
                      arrangeres pubquiz, musikkinnslag av diverse
                      artister/trubadurer og andre sosiale happenings.
                    </p>
                  </div>
                </FadeInSection>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="room-ameneties">
        <div className="room-parallax-image">
          <div className="parallax-image_bg-bolette">
            <div className="contact-content-rom">
              Hvis du har <br></br> spørsmål, <br></br> ring oss gjerne!
              <span className="bolette-number"> Tel: 57 75 25 55</span>
            </div>
          </div>
        </div>
      </section>
      {/* GALLERY */}
      <section className="section-six">
        <div className="flex-container">
          {/* eyebrow */}
          <div className="eyebrow mb">
            <div
              className="eyebrow__number eyebrow__item"
              data-animation="number"
            >
              <span>02</span>
            </div>
            <div
              className="eyebrow__line eyebrow__item"
              data-animation="line"
            ></div>
            <div className="eyebrow__text eyebrow__item" data-animation="text">
              <h5>Instagram</h5>
            </div>
          </div>
          {/* title */}
          <div className="title">
            <h2>FINN OSS PÅ INSTAGRAM</h2>
          </div>

          <div className="container-small">
            <ImageSliderHelmers />
          </div>
        </div>
      </section>
    </>
  );
}

export default Helmers;
