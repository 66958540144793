import React, { Component } from "react";
import Modal from "../components/modal";
import { IoSearchOutline } from "react-icons/io5";
import SimpleLineIcon from "react-simple-line-icons";

import "../App.scss";

class RoomCards extends Component {
  constructor() {
    super();

    this.state = {
      showModal: null,
    };
  }

  getModal = (data) => {
    this.setState({ showModal: data });
  };
  hideModal = () => {
    this.setState({ showModal: null });
  };

  render() {
    return (
      <>
        {this.props.data.map((data) => {
          return (
            <div className="card__container" key={data.id}>
  
               {/* <IoSearchOutline
                  size={70}
                  color="white"
                  className="glass-overlay"
                  onClick={() => this.getModal(data)}
                /> */}
  

              <div className="black-hover">
             
                <img
                  src={data.image[0]}
                  onClick={() => this.getModal(data)}
                  alt=" rom comfort hotel Victoria Florø"
                  className="mt-card"
                />
              </div>
       
       
              <div className="room-card-container">
                <div className="card__title">
                  <h2 className="card__subtitle">{data.name}</h2>
                  <h3 className="card_under_subtitle">{data.subtitle}</h3>
                </div>
                <div className="white-background">
                  <div className="room-card">
                    <p className="card__description left">{data.paragraph}</p>
                    <ul className="right">
                      {data.list.map((item) => (
                        <li key={item}>{item}</li>
                      ))}
                    </ul>
                  </div>

                  <a
                    className="nordic-choice-link"
                    href="https://www.nordicchoicehotels.no/hotell/norge/floro/comfort-hotel-floro/?gclid=CjwKCAjwtKmaBhBMEiwAyINuwA92RJ87R5wyT10C6BQYi1XQHHrRv28QET-r4rZglYCw83U2fR4whRoCnFUQAvD_BwE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="room_button">
                      {/* <div className="arrow-right-room">
                        {" "}
                        <i>
                          <SimpleLineIcon name="arrow-right" size="small" />
                        </i>
                      </div> */}
                      <span>BESTILL OVERNATTING</span>
                    </button>
                  </a>
                  {/* CONDITIONALY RENDERING DESIRED IMAGE  */}
                  {this.state.showModal && (
                    <Modal
                      onHide={this.hideModal}
                      images={this.state.showModal?.image}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}
export default RoomCards;
