import React, { Component } from "react";
import RoomCards from "../components/roomCards";
import roomData from "../Data/roomData";
import Hero from "../components/hero";
import R from "../Video/rom.mp4";
import "../pages/styles/Room.scss";

import SimpleLineIcon from "react-simple-line-icons";

class Room extends Component {
  constructor() {
    super();
    this.state = {
      data: roomData,
    };
  }

  render() {
    return (
      <>
        {/* HERO  */}
        <Hero hero="roomsHero">
          <div className="overlay-hero"></div>
          <video
            autoPlay
            loop
            muted
            playsInline
            src={R}
            className="fill"
            type="video/mp4"
          />

          <div className="call-to-action">
            <div className="text-center">
              <h1 className="fade-up inner-heading">
                {/* <span className="tall-font-2">Hotel </span> */}
                <span className="curved-2">ROM</span>
              </h1>
            </div>
          </div>
        </Hero>

        {/*CARDS*/}
        <div className="container">
          <section className="cards-section">
            <RoomCards data={this.state.data} />
          </section>
        </div>

        {/* CONTACT */}

        <div className="room-parallax-image">
          <div className="parallax-image_bg">
            <div className="overlay-hero-arrengment "> </div>
            <div className="contact-content-rom">
              Hvis du har<br></br> spørsmål<br></br> kontakt oss gjerne!
              <a href="mailto:co.floro@strawberry.no?">
                {" "}
                <button className="room-button">
                  {" "}
                  <span className="arrow-right-contact">
                    <SimpleLineIcon name="arrow-right" size="small" />
                  </span>
                  <span className="contact-margin">Kontakt oss</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Room;
