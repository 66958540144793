import React, { Component } from "react";
import { Dialog } from "@mui/material";

import { IoCloseOutline } from "react-icons/io5";
import { RiArrowLeftSLine } from "react-icons/ri";
import { RiArrowRightSLine } from "react-icons/ri";
import "../pages/styles/Room.scss";

class Modal extends Component {
  state = {
    index: 0,
  };

  next = () => {
    const { images } = this.props;
    this.setState(({ index }) => ({
      index: Math.min(images.length - 1, index + 1),
    }));
  };

  prev = () => {
    this.setState(({ index }) => ({
      index: Math.max(0, index - 1),
    }));
  };

  render() {
    const { index } = this.state;
    const { images, onHide } = this.props;
    return (
      <Dialog open={true} onClose={onHide}>
        <img className="modal-image__placeholder" alt="" src={images[index]} />
        <IoCloseOutline
          className="close-modal"
          onClick={this.props.onHide}
          color={"black"}
          size={30}
        />
        <RiArrowRightSLine
          onClick={this.next}
          color={"white"}
          size={20}
          className="arrow modal-right"
        />

        <RiArrowLeftSLine
          onClick={this.prev}
          color={"white"}
          size={20}
          className="arrow modal-left"
        />
      </Dialog>
    );
  }
}

export default Modal;
