import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/white.png";
import logoBlack from "../assets/images/black.png";
import { useLocation } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { VscMenu } from "react-icons/vsc";
import "../App.scss";

const Navbar = () => {
  const [clicked, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  // const [headerDecor, setHeader] = useState(false);
  //  location to set navbar color on specific pages
  const location = useLocation();
  const [navbarLogo, setNavbarLogo] = useState(logo);
  const [menu, setMenu] = useState({
    color: "black",
  });
  //   const [mobile, setMobile] = useState({
  //   color:"red"
  //  })

  // const [nav, setNav] = useState();

  useEffect(() => {
    changeLogo();
    window.addEventListener("scroll", changeLogo);
  }, [location]);

  const changeLogo = () => {
    // Change navbar background color for specific pages and position of scroll
    if (
      window.scrollY >= 80 ||
      location.pathname === "/vilkar" ||
      location.pathname === "/personvern" ||
      location.pathname === "/kontakt"
    ) {
      setNavbar(true);
      setNavbarLogo(logoBlack);
      setMenu({ color: "black" });

      // setHeader(true);
    } else {
      setNavbarLogo(logo);
      setMenu({ color: "white" });
      // setClick(!clicked)
    }
  };

  const handleClick = () => {
    setClick(!clicked);
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //   const goToTopMore = () => {
  //   window.scrollTo({
  //     top: 85,
  //     behavior: "smooth",
  //   });
  // };

  // const changeMobile = () => {
  //   if (clicked) {
  //     setNavbar(false)
  //   }
  //   else {
  //     setNavbar(true)
  //     setMenu({ color: "black" })
  //     setNavbarLogo(logoBlack);
  //   }
  // }

  const changeBackround = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackround);

  return (
    <>
      <header className="header">
        <nav className={navbar ? "navbar active" : "navbar"}>
          <div className="grid-container-navbar  header-container pt-32">
            <div className="d-flex  justify-content-between flex-wrap position-relative ">
              <div className="logo left-side">
                <Link to="/">
                  <img
                    className="nav-logo"
                    src={navbarLogo}
                    alt="nordicchoicehotels"
                    onClick={() => {
                      goToTop();
                    }}
                  />
                </Link>
              </div>
              <div className=" right-side mr-75">
                <div
                  className="off-canvas-menu menu-bar"
                  onClick={() => {
                    handleClick();
                    setNavbar(true);
                    setNavbarLogo(logoBlack);
                    setMenu({ color: "black" });
                  }}
                >
                  {clicked ? (
                    <AiOutlineClose size={30} />
                  ) : (
                    <VscMenu
                      size={30}
                      color={menu.color}
                      //  onClick={changeMobile}
                    />
                  )}
                </div>
              </div>
            </div>
            {/* <div className={headerDecor ? "header__decor" : "header"}></div> */}
          </div>
        </nav>
        <div className="container-menu">
          <ul className={clicked ? "menu-is-active" : "menu"}>
            <div className="full-height row-2 items-center">
              <div className="column column-6 ">
                <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links"
                    onClick={() => {
                      handleClick();
                      goToTop();
                      changeBackround();
                    }}
                    to="/"
                  >
                    Om oss
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links"
                    onClick={() => {
                      handleClick();
                      goToTop();
                      changeBackround();
                    }}
                    to="/rom"
                  >
                    Rom
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links "
                    onClick={() => {
                      handleClick();
                      goToTop();
                      changeBackround();
                    }}
                    to="/arrangement"
                  >
                    Arrangement
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links "
                    onClick={() => {
                      handleClick();
                      goToTop();
                      changeBackround();
                    }}
                    to="/konferanse"
                  >
                    Konferanse
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links "
                    onClick={() => {
                      handleClick();
                      goToTop();
                      changeBackround();
                    }}
                    to="/restaurant"
                  >
                    Restaurant | Bolette
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links "
                    onClick={() => {
                      handleClick();
                      goToTop();
                      changeBackround();
                    }}
                    to="/vinkjeller"
                  >
                    Vinkjeller | Fru Helmers
                  </Link>
                </li>

                {/* FUTURE GIFTCARD */}

                {/* <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links"
                    onClick={handleClick}
                    to="/gavekort"
                  >
                    Gåvekort
                  </Link>
                </li> */}

                <li className="nav-item">
                  <Link
                    activeclassname="active"
                    className="nav_links "
                    onClick={() => {
                      handleClick();
                      goToTop();
                      changeBackround();
                    }}
                    to="/kontakt"
                  >
                    Kontakt
                  </Link>
                </li>
                <li className="nav-item">
                  <button className="nav_links nav-links-mobile" type="button">
                    <a href="https://www.nordicchoicehotels.no/hotell/norge/floro/comfort-hotel-floro/romtyper/#romtyper">
                      Bestill overnatting
                    </a>
                  </button>
                </li>
              </div>
              <div className="column-6">
                <div className="row-2">
                  <div className="col-left">
                    <h3 className="navbar-socials">Kontaktinfo</h3>
                    <p className="info-white">
                      Markegata 43
                      <br></br>6900 Florø
                    </p>
                    <p className="info-white">co.floro@strawberry.no</p>

                    <p className="info-white">57 75 25 55</p>
                  </div>
                  <div className="col-right">
                    <h3 className="navbar-socials">kontakt oss</h3>
                    <ul>
                      <p>
                        {" "}
                        <a
                          className="info-white"
                          href="https://www.facebook.com/comforthotelfloro"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook{" "}
                        </a>
                      </p>
                      <p className="info-white">
                        {" "}
                        <a
                          className="info-white"
                          href="https://www.instagram.com/comfort_floro/?hl=en"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Instagram{" "}
                        </a>
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Navbar;
