import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaInstagram } from "react-icons/fa";
import {} from "react-icons/fa";
import img13 from "../assets/images/img13.jpg";
import img14 from "../assets/images/img14.jpeg";
import img15 from "../assets/images/img15.jpg";
import img16 from "../assets/images/img16.jpeg";

function ImageSlider() {
  let settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    dots:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="card-wrapper">
        <div className="card">
          <a
            href="https://www.instagram.com/p/Cj4f20RtzwE/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="card-image">
              <img src={img15} alt="" />
            </div>
            <ul className="social-icons">
              <li>
                <i>
                  {" "}
                  <FaInstagram size={30} color="white" opacity={0.4}/>
                </i>
              </li>
            </ul>
          </a>
          <div className="details">
            <h2>
              <span className="job-title">comfort_floro</span>
            </h2>
          </div>
        </div>
      </div>

      <div className="card-wrapper">
        <div className="card">
          <a
            href="https://www.instagram.com/p/ChJgqjlqw_6/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="card-image">
              <img src={img13} alt="" />
            </div>
            <ul className="social-icons">
              <li>
                <i>
                  <FaInstagram size={30} color="white"opacity={0.4} />
                </i>
              </li>
            </ul>
          </a>
          <div className="details">
            <h2>
              <span className="job-title">comfort_floro</span>
            </h2>
          </div>
        </div>
      </div>

      <div className="card-wrapper">
        <div className="card">
          <a
            href="https://www.instagram.com/p/CZwgrYLNbrw/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="card-image">
              <img src={img16} alt="" />
            </div>
            <ul className="social-icons">
              <li>
                <i>
                  {" "}
                  <FaInstagram size={30} color="white"opacity={0.4} />
                </i>
              </li>
            </ul>
          </a>
          <div className="details">
            <h2>
              <span className="job-title">comfort_floro</span>
            </h2>
          </div>
        </div>
      </div>

    

      <div className="card-wrapper">
        <div className="card">
          <a
            href="https://www.instagram.com/p/CbaSfUhM9AI/?utm_source=ig_web_copy_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="card-image">
              <img src={img14} alt="" />
            </div>
            <ul className="social-icons">
              <li>
                <i>
                  {" "}
                  <FaInstagram size={30} color="white"opacity={0.4} />
                </i>
              </li>
            </ul>
          </a>
          <div className="details">
            <h2>
              <span className="job-title">comfort_floro</span>
            </h2>
          </div>
        </div>
      </div>
    </Slider>
  );
}

export default ImageSlider;
