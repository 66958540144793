// this will have to be in public folder
import standard1 from "../assets/images/standard1.jpg";
import standard from "../assets/images/standard.jpg";
import standard3 from "../assets/images/standard3.jpg";
import double from "../assets/images/double.jpg";
import double1 from "../assets/images/double1.jpg";
import double2 from "../assets/images/double2.jpg";
import superior from "../assets/images/superior.jpg";
import superior1 from "../assets/images/superior1.jpg";
import superior2 from "../assets/images/superior2.jpg";
import twin1 from "../assets/images/twin1.jpg";
import deluxe from "../assets/images/deluxe.jpg";
import deluxeBath from "../assets/images/deluxeBath.jpg";
import deluxeBath2 from "../assets/images/deluxeBath2.jpg";

const roomData = [
  {
    id: 1,
    name: "Standard enkeltrom",
    subtitle: "Maks 1 person",
    paragraph:
      "Hotellets standard enkeltrom passer perfekt til deg som reiser alene. Rommene er møblert med enten en 90 cm seng og baderom med dusj. ",
    list: ["Røyking ikke tillatt", "Standardrom", "Frokost inkludert"],
    image: [standard, standard1, standard3],
  },
  {
    id: 2,
    name: "Standard dobbeltrom",
    subtitle: "Maks 2 person",
    paragraph:
      "Hotellets standard dobbeltrom passer perfekt for en til to personer. Rommet er utstyrt med en 160cm queen bed, skrivebord og lenestol.",
    list: ["Røyking ikke tillatt", "Standardrom", "Frokost inkludert"],
    image: [double, double1, double2],
  },

  {
    id: 3,
    name: "Standard / Twin",
    subtitle: "Maks 2 personer",
    paragraph:
      "Våre standard dobbeltrom er møblert med to 90 cm brede enkeltsenger, skrivebord, lenestol, og baderom med badekar. Passer perfekt for en til to personer", 

    list: ["Røyking ikke tillatt", "Standardrom", "Frokost inkludert"],
    image: [twin1],
    // image: [twin1, twosinglebath],
  },
  {
    id: 4,
    name: " Deluxe ",
    subtitle: "Max 2 personer + 3 extra",
    paragraph:
      "Deluxe dobbeltrom er et romslig og behagelig rom møblert med enten to twin senger eller en stor 160cm queen bed. Passer ypperlig for en stor familie. ",

    list: ["Røyking ikke tillatt", "Deluxe-rom", "Frokost inkludert"],

    image: [superior, superior1, superior2],
  },
  {
    id: 5,
    name: " Superior ",
    subtitle: "Maks 2 personer + 1 ekstra",
    paragraph:
      "Superior dobbeltrom er et romslig og behagelig rom møblert med en 160cm queen bed for deg som ønsker større plass under oppholdet. Rommet er møblert med skrivebord, sittegruppe og har baderom med badekar.",
    list: ["Røyking ikke tillatt", "Superiorrom", "Frokost inkludert"],
    image: [deluxe, deluxeBath, deluxeBath2],
  },
];
export default roomData;
