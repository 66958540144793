import React from "react";
import { useState, useEffect } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 2500) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {showTopBtn && <MdKeyboardArrowUp className="scroll" onClick={goToTop} />}
    </>
  );
};

export default ScrollToTop;
