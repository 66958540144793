import React from "react";
import final from "../Video/final.mp4";

// const final = lazy(() => import("../Video/final.mp4"));

const video = () => {
  return (
    <div className="main">
      <div className="overlay-hero"></div>
      <video
        autoPlay
        loop
        muted
        playsInline
        src={final}
        type="video/mp4"
        // class="lazy"
      />
      <div className="call-to-action">
        <div className="cta-container text-center ">
          <h1 className="fade-up inner-heading">
            <span className="font-first">Velkommen til</span>
            <br />
            <span className="tall-font-2">VICTORIA</span>
            <span className="curved">Hotel</span>
          </h1>
          <div className="btn-cta">
            <a
              href="https://www.nordicchoicehotels.no/hotell/norge/floro/comfort-hotel-floro/?gclid=CjwKCAjw-rOaBhA9EiwAUkLV4jwb-MRG-R8GY6M07GrJx8tnWVHRzzLIRBKhUZ1W6pi5y7ahPGSSoxoCldEQAvD_BwE"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_1 header-btn mr-75 d-none d-sm-inline-block white-bg"
            >
              BESTILL ROM
            </a>
            <a
              href="https://booking.resdiary.com/widget/Standard/BoletteRestaurant/29242"
              target="_blank"
              rel="noopener noreferrer"
              className="btn_1 header-btn mr-75 d-none d-sm-inline-block"
            >
              BESTILL BORD
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default video;
