import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import Hero from "../components/hero";
import bolette from "../Video/bolette.mp4";
import bol from "../assets/images/bol.jpg";
import bar from "../assets/images/bar.jpg";
import plate from "../assets/images/plate.svg";
import gluten from "../assets/images/gluten.svg";
import family from "../assets/images/family.svg";
import plant from "../assets/images/plant.svg";
import SimpleLineIcon from "react-simple-line-icons";
import ImageSlider from "../components/sliderBolette";
import "../pages/styles/Bollete.scss";
import Reviews from "../components/reviews";


function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = useRef();
  useLayoutEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
function Bollete() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setOffset(window.pageYOffset);
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Hero>
        <div>
          <div className="overlay-hero-bolette"></div>
          <video
            className="video-overlay"
            autoPlay
            loop
            muted
            playsInline
            src={bolette}
            type="video/mp4"
          ></video>
        </div>
      </Hero>
      {/* INTRO */}

      <section className=" container layers two-halves bg-gray two-halves_l padding-top">
        <div className="grid-container two-halves_bg layers_center ">
          <div className="grid-x grid-margin-x ">
            <div className="cell large-7 large-offset-5">
              <div className="two-halves__img parallax-image-bolette">
                <div className="image-container ">
                  <img
                    className="parallax-image__item cover bolette-img"
                    src={bol}
                    alt="comfort hotel victoria florø"
                    width="242"
                    height="363"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <FadeInSection>
          <div className="grid-container layers__center two-halves__overlay">
            <div className="grid-x grid-margin-x">
              <div className="cell large-6 large-offset-1">
                <div className="two-halves__content">
                  <div className="grid-x grid-margin-x">
                    <div className="cell large-8 large-offset-2 mobile-bolette">
                      {/* <FadeInSection> */}
                      <div className="eyebrow ">
                        <div
                          className="eyebrow__number eyebrow__item"
                          data-animation="number"
                        >
                          <span>01</span>
                        </div>
                        <div
                          className="eyebrow__line eyebrow__item"
                          data-animation="line"
                        ></div>
                        <div
                          className="eyebrow__text eyebrow__item"
                          data-animation="text"
                        >
                          <h5>RESTAURANT </h5>
                        </div>
                      </div>

                 
                        <h2 data-split-text="true" aria-label="">
                          <span className="word1" aria-hidden="true"></span>{" "}
                        </h2>
                        <h2> Bolette</h2>
                      {/* </FadeInSection>
                      <FadeInSection> */}
                        <div className="editable">
                          <p></p>
                          <p className="text-separator">
                            Restaurant Bolette sitt motto er <cite>easy - planet -
                            enjoy.</cite> Med disse ordene ønsker vi å formidle at
                            Bolette skal være et naturlig og sosialt møtested
                            for alle anledninger. Her skal det være lett å komme
                            innom både for kaffe, cocktails eller middag. Vi er
                            opptatt av bærekraft og vil gjøre vårt for å ta vare
                            på både mennesker og naturen. Men det viktigste av
                            alt, gjennom god mat og drikke, deilig musikk og
                            hyggelig stemning skal vi alltid sørge for at våre
                            gjester stortrives hos oss.
                            
                          </p>
                          <p></p>
                          <div className="facilities">
                            <div className="facilities__content">
                              <div className="facilities__item">
                                <div className="facilities-item facilities-item-horizontal">
                                  <i className="icon-open-breakfast-dinner">
                                    {" "}
                                    <img
                                      src={plate}
                                      alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                      width={45}
                                    />
                               
                                  </i>

                                  <span>
                                    Frokost og fristende menyer
                                  </span>
                                </div>
                              </div>

                              <div className="facilities__item">
                                <div className="facilities-item facilities-item-horizontal">
                                  {" "}
                                  <img
                                    src={plant}
                                    alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                    width={40}
                                    className="icon-open-breakfast-dinner"
                                  />
                                  <span className="icon-vegan">
                                    Vegansk og vegetar alternativer
                                  </span>
                                </div>
                              </div>

                              <div className="facilities__item">
                                <div className="facilities-item facilities-item-horizontal">
                                  <i className="icon-open-breakfast-dinner">
                                    {" "}
                                    <img
                                      src={gluten}
                                      alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                      width={40}
                                    />
                                  </i>

                                  <span >
                                    Glutenfrie alternativer tilgjengelig
                                  </span>
                                </div>
                              </div>

                               <div className="facilities__item">
                                <div className="facilities-item facilities-item-horizontal">
                                  <i className="icon-open-breakfast-dinner">
                                    {" "}
                                    <img
                                      src={family}
                                      alt="dinner plate at Bolette restaurant in Comfort hotel Victoria Florø"
                                      width={40}
                                    />
                                  </i>

                                  <span className="center">
                                    Familievennlig
                                  </span>
                                </div>
                              </div>
                         
                            </div>
                             

                              <div
                                className="restaurant-button-bolette flex"
                                type="button"
                              >
                                <div className="bolette-flex">
                                  {/* <div className="arrow-right-bolette">
                                    {" "}
                                    <i>
                                      <SimpleLineIcon
                                        name="arrow-right"
                                        size="small"
                                      />
                                    </i>
                                    <div className="explore">
                                      <a
                                        className="meny-big"
                                        href={pdf}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Meny
                                      </a>
                                    </div>
                                  </div> */}

                                  <div className="arrow-right-bolette">
                                    {" "}
                                    <i>
                                      <SimpleLineIcon
                                        name="arrow-right"
                                        size="small"
                                      />
                                    </i>
                                    <div className="explore">
                                      <a
                                        className="meny-big"
                                        href="https://www.facebook.com/Bolette03"
                                        target="_blank"
                                        rel="noreferrer"
                                        
                                      >
                                        Facebook
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="facilities-button button-white-bg">
                              <a  className="black-links" href="https://booking.resdiary.com/widget/Standard/BoletteRestaurant/29242">
                                <button className="book-now facilities-button">
                                  BORDRESERVASJON
                                </button>{" "}
                              </a>
                            </div>
                        
                        </div>
                      {/* </FadeInSection> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>
      </section>

      {/* Bar section */}
      <section className="event-area fix section-padding">
        <div className="container">
          <div className="bar-margin-top">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <FadeInSection>
                  <img
                    className="ev-1"
                    src={bar}
                    alt=" bar at comfort hotel victoria florø"
                  />
                </FadeInSection>
              </div>

              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className="event-caption">
                    <div className="eyebrow">
                      <div
                        className="eyebrow__number eyebrow__item"
                        data-animation="number"
                      >
                        <span>02</span>
                      </div>
                      <div
                        className="eyebrow__line eyebrow__item"
                        data-animation="line"
                      ></div>
                      <div
                        className="eyebrow__text eyebrow__item"
                        data-animation="text"
                      >
                        <h5>Bar</h5>
                      </div>
                    </div>

                    <h2 className="event-title mb">Bolette Bar</h2>
                    <p>
                      I vår lobbybar skal det være lett å komme innom for både
                      en kaffe eller en cocktail. Med god drikke, deilig musikk
                      og hyggelig stemning skal vi alltid sørge for at våre
                      gjester stortrives hos oss.
                    </p>
                  </div>
                </FadeInSection>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="room-ameneties">
        <div className="room-parallax-image">
          <div className="parallax-image_bg-bolette">
            <div className="overlay-hero-arrengment "> </div>
            <div className="contact-content-rom">
              Hvis du har <br></br> spørsmål, <br></br> ring oss gjerne!
              <div className="bolette-number">Tel: 57 75 25 55</div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-five">
        <div className="flex-container">
          {/* eyebrow */}
          <div className="eyebrow mb">
            <div
              className="eyebrow__number eyebrow__item"
              data-animation="number"
            >
              <span>03</span>
            </div>
            <div
              className="eyebrow__line eyebrow__item"
              data-animation="line"
            ></div>
            <div className="eyebrow__text eyebrow__item" data-animation="text">
              <h5>Instagram</h5>
            </div>
          </div>
          {/* title */}
          <div className="title">
            <h2>FINN OSS PÅ INSTAGRAM</h2>
          </div>

          <div className="container-small">
            <ImageSlider />
          </div>
        </div>
      </section>

      {/* REVIEWS */}
      <div className="section-four">
        <div className="container-small">
          <div className="flex-container">
            <div className="eyebrow mb">
              <div
                className="eyebrow__number eyebrow__item"
                data-animation="number"
              >
                <span>04</span>
              </div>
              <div
                className="eyebrow__line eyebrow__item"
                data-animation="line"
              ></div>
              <div
                className="eyebrow__text eyebrow__item"
                data-animation="text"
              >
                <h5>UTTALELSER</h5>
              </div>
            </div>
            <div className="title">
              <h2>
                Våre gjester har blant annet<br></br>sagt følgende om oss
              </h2>
            </div>
          </div>
          <Reviews />
        </div>
      </div>
    </>
  );
}

export default Bollete;
