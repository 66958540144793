/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

function map() {
  return (
    <div className="map-responsive">
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7590.668896272124!2d5.0356071!3d61.5997871!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9a2c6201a278a856!2sComfort%20Hotel%20Flor%C3%B8!5e0!3m2!1shr!2sno!4v1642597017953!5m2!1shr!2sno"
        width="800"
        height="497"
        frameBorder="0"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
      ></iframe> */}
     <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Comfort%20Hotel%20Victoria%20Markegata%2043,%206900%20Flor%C3%B8%20+(Comfort%20Hotel%20Victoria%20)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a></iframe>
    </div>
  );
}

export default map;
