import React from "react";
import Hero from "../components/hero";
import { Link } from "react-router-dom";

const Terms = () => {
  return (
    <>
      <Hero hero="termsHero"></Hero>
      <div className="content-wrapper">
        <div className="container-small">
          <div className="block">
            <h3 className="h3-big">
              Personvernerklæring for Comfort Hotel Victoria
            </h3>
            <p className="inner-margin">
              Her finner du brukervilkårene for våre nettsider. Ved spørsmål ber
              vi deg kontakte kundeservice, enten via e-post
              co.floro@strawberry.no eller via telefon +47 57 75 25 52
            </p>
            <p className="inner-margin">
              Vi viser i tillegg til vår <span></span>
              <Link
                activeclassname="active"
                className="mail-link"
                to="/personvern"
              >
                Personvernerklæring
              </Link>
            </p>
            <p className="white-prewrap">1. Nettsiden og dennes vilkår</p>
            <p className="inner-margin white-prewrap">
              1.1 Denne nettside eies og drives av Victoria Hotell Florø AS reg
              .
            </p>
            <p className="inner-margin white-prewrap">
              1.2 Disse vilkår («Brukervilkårene») gjelder for din bruk av
              nettsiden. Gjennom å besøke og bruke nettsiden, er du bundet av de
              til enhver tid gjeldende vilkår for nettsiden.
            </p>
            <p className="inner-margin white-prewrap">
              1.3 For brukere som registrerer seg som medlem i Nordic Choice
              kundeklubb, Nordic Choice Club, gjelder foruten Brukervilkårene
              også Nordic Choice Club Medlemsvilkår.
            </p>
            <p className="inner-margin white-prewrap">
              1.4 Brukerne forplikter seg til ikke å krenke norsk lov ved bruk
              av nettsiden.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">
              2. Endring av nettside og brukervilkår
            </p>
            <p className="inner-margin white-prewrap">
              2.1 For å forbedre din bruk av nettsiden vil Victoria Hotell Florø
              AS kunne komme til å gjøre endringer på den.
            </p>
            <p className="inner-margin white-prewrap">
              2.2 Comfort Hotell Victoria Florø forbeholder seg retten til, uten
              forutgående varsel, å gjøre de forbedringer, forandringer, tillegg
              eller fjerning av informasjon på nettsiden som Comfort Hotell
              Victoria Florø anser nødvendig. Videre for beholder Comfort Hotell
              Victoria Florø seg retten til å gjøre endringer i Brukervilkårene.
              Det til enhver tid gjeldende Brukervilkår vil være publisert på
              våre nettsider. Ved å fortsette å bruke nettsiden etter at slike
              endringer har blitt publisert, aksepterer du endringene.
            </p>
            <p className="white-prewrap-m3"> 3. Immaterielle rettigheter</p>
            <p className="inner-margin white-prewrap">
              3.1 Alt innhold på nettsiden eies, om ikke annet angis, av Comfort
              Hotell Victoria Florø AS..
            </p>
            <p className="inner-margin white-prewrap">
              3.2 Nettsidens innhold er beskyttet av opphavsrettslige,
              varemerkerettslige og andre immaterialrettslige bestemmelser.
              Brukere gis ikke ved noe tilfelle rett til å bruke nettsidens
              innhold på annen måte enn i henhold til Brukervilkårene.
            </p>
            <p className="inner-margin white-prewrap">
              3.3 Når ikke noe annet angis krever gjengivelse, publisering,
              bearbeidelse, overføring, lagring eller annen anvendelse av
              innholdet på nettsiden, forutgående skriftlige godkjennelse fra
              eller Nordic Choice. Unntak gjøres for tilfeldig lagring på datoer
              eller utskrift for personlig bruk.
            </p>
            <p className="inner-margin white-prewrap">
              3.4 Bruk av varemerker og logotyper som forekommer på nettsiden,
              Victoria Hotell Florø AS eller Nordic Choice eller andre berørte
              rettighetsinnehaveren forutgående skriftlige samtykke.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap-m3">4. Hyperlinker</p>
            <p className="inner-margin white-prewrap">
              4.1 Vår nettside kan inneholde hyperlinker til nettsider som eies
              og er levert av tredjemann. Din bruk av slike linker innebærer at
              du forlater vår nettside.
            </p>
            <p className="inner-margin white-prewrap">
              4.2 Vi råder ikke i noe tilfelle over utformingen av, eller kan
              påvirke innholdet på, nettsider som er levert av tredjemenn.
            </p>
            <p className="inner-margin white-prewrap">
              4.3 At vi tilbyr slike linker innebærer ikke at vi stiller oss bak
              eller har ansvar for informasjonen, produktet eller tjeneste som
              tilbys via hyperlinken.
            </p>
            <p className="inner-margin white-prewrap">
              4.4 Ettersom vi ikke har ansvar for håndtering og personvern av
              din personlige informasjon på tredjemanns nettsider, anbefaler vi
              deg å lese de vilkår og betingelser som gjelder for din bruk av
              slike nettsider. Bruk av sider tilhørende tredjemann skjer
              utelukkende på brukerens risiko.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap-m3">5. Ansvarsgebrensning</p>
            <p className="inner-margin white-prewrap">
              5.1 Victoria Hotell Florø AS gir ingen garantier vedrørende
              nettsidens funksjon eller tilgjengelighet.
            </p>
            <p className="inner-margin white-prewrap">
              5.2 Victoria Hotell Florø AS garanterer heller ikke at den
              informasjon som er tilgjengelig på nettsiden er korrekt eller
              fullstendig. Dette innebærer informasjon om så vel produkter,
              tjenester og tilbud samt annen informasjon som oppgitt på
              nettsiden.
            </p>
            <p className="inner-margin white-prewrap">
              5.3 Victoria Hotell Florø AS har verken ansvar for direkte eller
              indirekte skader forårsaket av nettstedets utnyttelse, eller
              informasjon på nettstedet, i den utstrekning slikt ansvar ikke
              følger av ufravikelig lovgivning.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap-m3">6. Sikkerhet på nettsiden</p>
            <p className="inner-margin white-prewrap">
              6.1 Victoria Hotell Florø AS, samt våre samarbeidsselskaper vil
              komme til å bruke den informasjon du oppgir ved transaksjoner på
              nettsiden. Slik informasjon omfatter kreditt- og
              betalingskortinformasjon samt adresser for leveranser og faktura.
            </p>
            <p className="inner-margin">
              6.2 Victoria Hotell AS tar alle rimelige skritt for å beskytte den
              informasjon du gir på dette området i forbindelse med
              transaksjoner.
            </p>
          </div>
        </div>
      </div>
      ;
    </>
  );
};

export default Terms;
