import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ImageSlider() {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className="card-wrapper-activities">
        <div className="reviews-card">
          <p className="quotations">
            Tusen takk for veldig god mat og kjempegod service! Fin og
            innholdsrik meny, veldig hyggelige og flinke servitører!
          </p>
        </div>
      </div>
      <div className="card-wrapper-activities">
        <div className="reviews-card">
          <p className="quotations2">
            Bolette Restaurant har virkelig tatt seg opp - dette liker vi! Fikk
            både drikke og mat raskt (som smakte nydelig). Servitørane hadde
            gjesten i fokus og vi følte oss sett og ivaretatt.
          </p>
        </div>
      </div>
      <div className="card-wrapper-activities">
        <div className="reviews-card">
          <p className="quotations">
            Fantastisk service, veldig god mat og veldig fornøyd med at vi kunne
            komme på så kort tid!
          </p>
        </div>
      </div>
      <div className="card-wrapper-activities">
        <div className="reviews-card">
          <p className="quotations">
          Var veldig god mat, trivelig betjening og god service i forkant. 
          </p>
        </div>
      </div>
      <div className="card-wrapper-activities">
        <div className="reviews-card">
          <p className="quotations">
           Hyggelig og god stemning. Var godt ivaretekne. 
          </p>
        </div>
      </div>
    </Slider>
  );
}

export default ImageSlider;
