import React, { useState, useLayoutEffect, useRef } from "react";
import Hero from "../components/hero";
import SimpleLineIcon from "react-simple-line-icons";
import big from "../assets/images/big.jpg";
import medium from "../assets/images/medium.jpg";
import small from "../assets/images/small.jpg";
import konferanse from "../Video/konferanse.mp4";
// const konferanse = lazy(() => import("../Video/konferanse.mp4"));

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();

  useLayoutEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

function Konferanse() {
  return (
    <>
      <Hero hero="konferanseHero">
        <div className="overlay-hero"></div>

        <div className="call-to-action">
          <div className="text-center">
            <h1 className="fade-up inner-heading padding curved-2">
              KONFERANSE
            </h1>
          </div>
        </div>
      </Hero>

      <section className="event-area fix section-padding">
        <div className="container">
          <div className="d-flex extra-margin konferanse-mt">
            <div className="intro-arrengment-left">
              <div className="eyebrow">
                <div
                  className="eyebrow__number eyebrow__item"
                  data-animation="number"
                >
                  <span>01</span>
                </div>
                <div
                  className="eyebrow__line eyebrow__item"
                  data-animation="line"
                ></div>
                <div
                  className="eyebrow__text eyebrow__item"
                  data-animation="text"
                >
                  <h5>Møte</h5>
                </div>
              </div>

              <FadeInSection>
                <h2 data-split-text="true" aria-label="">
                  <span className="word1" aria-hidden="true"></span>{" "}
                </h2>
                <h2>Konferanse</h2>
              </FadeInSection>
              <FadeInSection>
                <div className="editable pr ">
                  <p>
                    Klar for faglig påfyll og gode opplevelser? Vi tilbyr
                    konferanselokaler av ulik størrelse og hjelper dere med
                    glede til å skreddersy deres konferanse.
                  </p>
                  <br />

                  <p>
                    Alle våre møterom er utstyrt med prosjektor, skjerm,
                    høytalere og mikrofoner. Våre ansatte følger dere fra første
                    kontakt til gjennomført arrangement. Vi tilbyr ulike pakker
                    som inkluderer leie av konferanserom, moderne teknisk
                    utstyr, toretters-lunsj, kaffe, te, vann og frukt og
                    kaker/bakst.
                  </p>
                  <br />
                  <p>
                    Ta kontakt med oss for priser og tilpassing av ditt
                    arrangement.
                  </p>
                </div>

                <div className="facilities-button">
                  <a
                    className="black-links"
                    href="mailto:co.floro@strawberry.no?subject=Konferanse"
                  >
                    <div className="arrow-right-bolette">
                      <div>
                        {" "}
                        <i>
                          <SimpleLineIcon name="arrow-right mr" size="small" />
                        </i>
                      </div>
                      KONTAKT OSS
                    </div>{" "}
                  </a>
                </div>
              </FadeInSection>
            </div>

            <div className="intro-arrengment-right float-right">
              <video
                className="video-arrengment-konferanse"
                controls
                loop
                muted
                src={konferanse}
                type="video/mp4"
              ></video>
            </div>
          </div>
          {/*----------------- KONFERANSE CARD----------------- */}
          <div className="smaller_section_padding extra-margin mt-2">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <div>
                  <FadeInSection>
                    <img
                      className="ev-1"
                      src={big}
                      alt="konferanse comfort hotel victoria florø"
                    />
                  </FadeInSection>
                </div>
              </div>

              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className="event-caption">
                    <h2 className="event-title mb">C1</h2>
                    <p className="justify">
                      C1 er vårt største konferanserom og har kapasitet for opp
                      til 90 pers, avhengig av bordoppsett. C1 kan også benyttes
                      som selskapslokale og her har vi arrangert både bryllup,
                      konfirmasjoner og minnestund.
                    </p>
                    <div className="facilities-button">
                      <a
                        className="black-links"
                        href="mailto:co.floro@strawberry.no?subject=Konferanse"
                      >
                        <button className="book-now">KONTAKT OSS</button>{" "}
                      </a>
                    </div>
                  </div>
                </FadeInSection>
              </div>
            </div>
          </div>

          <div className="extra-margin">
            <div className="row  align-items-center mb-70">
              <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
                <FadeInSection>
                  <div className="event-caption  event-caption-3">
                    <h2 className="event-title mb">C2 </h2>
                    <p className="justify">
                      C2 er vårt mellomstore møterom og har kapasitet for 20-30
                      personer, avhengig av bordoppsett. C2 egner seg godt både
                      som møterom og som skjermet selskapslokale.
                    </p>
                    <div className="facilities-button">
                      <a
                        className="black-links"
                        href="mailto:co.floro@strawberry.no?subject=Selskap og arrengement"
                      >
                        <button className="book-now">KONTAKT OSS</button>{" "}
                      </a>
                    </div>
                  </div>
                </FadeInSection>
              </div>

              <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
                <div>
                  <img
                    className="ev-1 konferanse-c2"
                    src={medium}
                    alt="konferanse comfort hotel victoria florø"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row  align-items-center mb-70">
            <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-7">
              <div>
                <FadeInSection>
                  <img
                    className="ev-1"
                    src={small}
                    alt="konferanse comfort hotel victoria florø"
                  />
                </FadeInSection>
              </div>
            </div>

            <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-5">
              <FadeInSection>
                <div className="event-caption">
                  <h2 className="event-title mb">C3</h2>
                  <p className="justify">
                    C3 er vårt minste møterom og har kapasitet til 8 personer.
                    Møterommet egner seg perfekt til styremøter eller intervju.
                  </p>
                  <div className="facilities-button">
                    <a
                      className="black-links"
                      href="mailto:co.floro@strawberry.no?subject=Selskap og arrengement"
                    >
                      <button className="book-now">KONTAKT OSS</button>{" "}
                    </a>
                  </div>
                </div>
              </FadeInSection>
            </div>
          </div>
        </div>
        <div className="room-parallax-image-konferanse">
          <div className="parallax-image_bg-konferanse">
            <div className="overlay-hero-arrengment "> </div>
            <div className="contact-content-rom">
              Hvis du har <br></br> spørsmål, <br></br> ring oss gjerne!
              <div className="bolette-number">Tel: 57 75 25 55</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Konferanse;
