import React from "react";
import Hero from "../components/hero";

const Personvernvilkar = () => {
  return (
    <>
      <Hero hero="privacyHero"></Hero>
      <div className="content-wrapper">
        <div className="container-small">
          <div className="block">
            <h3 className="h3-big">
              Personvernerklæring for Comfort Hotel Victoria
            </h3>
            <p className="white-prewrap">1. Innledning</p>
            <p className="white-prewrap">
              Vi i Comfort hotel Victoria behandler dine personopplysninger i
              forskjellige sammenhenger, for eksempel når du bestiller tjenester
              av oss, overnatter på våre hoteller, bruker tjenester vi leverer
              og i enkelte andre sammenhenger. I vår personvernerklæring finner
              du mer informasjon om vår behandling av personopplysninger. Under
              finner du også kontaktinformasjon dersom du har spørsmål eller vil
              kreve innsyn.&nbsp;
            </p>
            <p className="white-prewrap">
              Vi behandler dine personopplysninger i samsvar med den til enhver
              tid gjeldende norske personopplysningsloven, herunder GDPR. &nbsp;
            </p>
            <p className="white-prewrap-m3"></p>

            <p className="white-prewrap">
              2. Behandlingsansvarlig for dine personopplysninger
            </p>
            <p className="white-prewrap">
              Vårt foretak Victoria Hotell AS, org.nr 980 333 388, postboks 43,
              6905 Florø, tlf +47 57 75 25 52, email&nbsp;
              <a className="mail-link" href="mailto:co.floro@strawberry.no">
                co.floro@strawberry.no
              </a>{" "}
              er behandlingsansvarlig for behandling av personopplysninger i
              våre systemer for booking, fakturering og hotelldrift. Vi er også
              behandlingsansvarlig for vår markedsføring og utsendelse av
              eposter ra ossf til våre kunder og kontakter.
            </p>

            <p className="white-prewrap-m3"></p>

            <p className="white-prewrap">
              3. Behandling av personopplysninger knyttet til booking og opphold
            </p>
            <p className="white-prewrap">
              I forbindelse med dine bookinger foretatt av deg selv eller av
              andre på vegne av deg, behandler vi de personopplysninger vi
              trenger for at vi skal kunne oppfylle avtalen om booking og kjøp
              av tjenester. Dette er opplysninger du har gitt oss direkte eller
              som du har gitt oss via reisebyrå eller agent. Vi behandler
              eksempelvis opplysninger om din identitet, dine
              kontaktopplysninger og din betalingsinformasjon. I noen tilfeller
              vil vi lagre ditt passnummer. I tillegg behandler vi andre
              opplysninger du måtte ha gitt oss og som har relevans for ditt
              opphold hos oss. Dette kan være informasjon om allergier eller om
              spesielle ønsker for ditt opphold. Vi registrerer alle kjøp og
              bestillinger du gjør hos oss som for eksempel spa, restaurant,
              roomservice etc, for å levere slike tjenester og for at du skal
              kunne betale for dem.
            </p>
            <p className="white-prewrap">
              Vi behandler disse opplysningene så lenge det er nødvendig for å
              oppfylle avtalen om booking med deg, og i tillegg så lenge
              gjeldende lovverk eller myndighetspålegg pålegger oss dette.{" "}
            </p>
            <p className="white-prewrap">
              Vi loggfører bruk av nøkkelkort i våre hotell. Dette gjør vi for å
              motvirke og oppklare kriminalitet, og av hensyn til sikkerhet,
              herunder brannsikkerhet. Vi lagrer loggene i 21 dager.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">
              4. Behandling av personopplysninger for markedsføringsformål
            </p>
            <p className="white-prewrap">
              Dersom du melder deg på våre nyhetsbrev lagrer vi og bruker vi din
              epostadresse til å sende deg nyheter og tilbud fra oss.{" "}
            </p>
            <p className="white-prewrap">
              Vi vil også bruke din e-postadresse eller telefonnummer til å
              sende deg nyheter og tilbud innenfor regler om eksisterende
              kundeforhold. Vårt grunnlag er markedsføringslovgivningen.{" "}
            </p>
            <p className="white-prewrap">
              Vi vil kontakte deg i sosiale medier etter ditt samtykke eller
              innenfor eksisterende kundeforhold. For å bruke sosiale medier som
              kommunikasjonskanal må vi levere ut din e-postadresse eller ditt
              telefonnummer til det sosiale mediet. Vårt grunnlag for dette er
              samtykke eller legitime interesser.{" "}
            </p>
            <p className="white-prewrap">
              Du kan til enhver tid trekke tilbake samtykker du har gitt oss. Du
              kan også reservere deg mot å motta markedsføring i eksisterende
              kundeforhold. Dette gjør du ved å sende en epost til
              co.floro@strawberry.no
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">
              5. Behandling av personopplysninger for utvikling, feilsøking og
              sikkerhet
            </p>
            <p className="white-prewrap">
              Vi vil behandle data som inkluderer personopplysninger for å gjøre
              feilsøking og rette feil, forbedre tjenestene våre og teknologien
              vi bruker og for å analysere bruk og brukeradferd. Videre vil vi
              behandle personopplysninger for å verifisere din identitet,
              herunder verifisere identitet i forbindelse med din bruk av våre
              digitale tjenester.
            </p>
            <p className="white-prewrap">
              Vi anonymiserer data eller utarbeider statistikk så langt vi
              klarer, men vil også måtte behandle personopplysninger for
              utviklings-, feilsøkings-, statistikk- og sikkerhetsformål.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">
              6. Behandling av personopplysninger for øvrig
            </p>
            <p className="white-prewrap">
              Dersom du kontakter vår kundeservice eller på annen måte henvender
              deg til oss med forespørsler, vil vi behandle personopplysninger
              du gir så langt det er nødvendig for å besvare og loggføre din
              henvendelse. Grunnlaget for dette er legitime interesser eller for
              å oppfylle avtaler med deg eller besvare dine henvendelser.{" "}
            </p>
            <p className="white-prewrap">
              Ut over behandling beskrevet i vår personvernerklæring eller
              basert på ditt samtykke, vil vi i noen tilfeller måtte eller kunne
              behandle personopplysninger når gjeldende lovverk, herunder
              personopplysningsloven og GDPR, gyldig myndighetspålegg eller
              domstol pålegger oss eller tillater oss dette.{" "}
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">
              7. Utlevering av personopplysninger og lovpålagt behandling
            </p>
            <p className="white-prewrap">
              Vi leverer ikke ut dine personopplysninger til tredjepart, med
              mindre du har samtykket til dette, eller med mindre gjeldende
              lovverk, herunder personopplysningsloven og GDPR, gyldig
              myndighetspålegg eller domstol tillater oss eller pålegger oss
              dette.{" "}
            </p>
            <p className="white-prewrap">
              For ordens skyld opplyser vi om at vår bruk av databehandler for å
              behandle opplysninger på vegne av oss, ikke regnes som utlevering.{" "}
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">8. Dine rettigheter </p>
            <p className="white-prewrap">
              Du som person har flere rettigheter etter
              personopplysningsregelverket.
            </p>
            <p className="white-prewrap">
              Du har rett til å kreve innsyn, retting eller sletting av
              personopplysningene vi behandler om deg. Du har videre rett til å
              kreve begrenset behandling, rette innsigelse mot behandlingen og
              kreve rett til dataportabilitet.{" "}
            </p>
            <p className="white-prewrap">
              For å ta i bruk dine rettigheter kan du gå til følgende side
              https://www.nordicchoicehotels.no/generelle-betingelser/generelle-betingelser/innsynsforesporsel/.
              Vi vil svare på din henvendelse til oss så fort som mulig, og
              senest innen 30 dager.{" "}
            </p>
            <p className="white-prewrap">
              Vi kommer til å be deg bekrefte din identitet eller be deg oppgi
              ytterligere informasjon før vi lar deg ta i bruk dine rettigheter
              overfor oss. Dette må vi gjøre for å forsikre oss om at vi kun gir
              tilgang til dine personopplysninger til deg - og ikke noen som gir
              seg ut for å være deg.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">9. Personvernombud</p>
            <p className="white-prewrap">
              Vi er en del av Nordic Choice Hotels. Nordic Choice Hotels har
              sitt eget personvernombud. Personvernombudet er ombud for alle
              hoteller tilknyttet Nordic Choice Hotels, inkludert for oss.{" "}
            </p>
            <p className="white-prewrap">
              Personvernombudet er vårt kontaktpunkt for Datatilsynet.
            </p>
            <p className="white-prewrap">
              Ombudet vårt gir alle hoteller i Nordic Choice Hotels, våre
              databehandlere og våre ansatte råd og veiledning om behandling av
              personopplysninger og reglene for dette. Ombudet jobber med å
              kontrollere vår overholdelse av reglene for personopplysninger og
              våre interne retningslinjer.
            </p>
            <p className="white-prewrap">
              Ombudet vårt kan også hjelpe deg med å ivareta dine rettigheter
              overfor oss, eller hjelpe deg med å få svar på spørsmål om dine
              personopplysninger hos oss.{" "}
            </p>
            <p className="white-prewrap">
              Du kan kontakte vårt personvernombud på DPO@strawberry.no.
            </p>
            <p className="white-prewrap">
              Dersom du mener at vår behandling av personopplysninger ikke
              stemmer med det vi har beskrevet her eller at vi på andre måter
              bryter personvernlovgivningen, så kan du alternativt klage til
              Datatilsynet i Norge, eller tilsynsmyndigheten i det land du bor
              på hotell i. Du finner informasjon om hvordan kontakte
              Datatilsynet på Datatilsynets nettsiderwww.datatilsynet.no.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">10. Cookies/Informasjonskapsler</p>
            <p className="white-prewrap">
              Vi bruker informasjonskapsler (cookies) for å forbedre
              brukeropplevelsen av våre nettsider. En cookie er en liten
              tekstbasert datafil som plasseres på ditt utstyr (din
              smarttelefon, datamaskin eller for eksempel nettbrett). Cookien
              hjelper til med å kjenne igjen hvilken type innhold og hvilke
              sider som besøkes på vårt nettsted. Informasjon som lagres ved
              hjelp av en cookie kan være hvordan du bruker nettstedet, hva
              slags type av nettleser du benytter, hvilke nettsider du har
              besøkt og hva slags språk du har valgt.
            </p>
            <p className="white-prewrap">
              En permanent cookie ligger igjen på ditt utstyr for et bestemt
              tidsrom. En sesjonscookie lagres tilfeldig i ditt utstyrs minne
              mens du er inne på våre hjemmesider. Sesjonscookien forsvinner når
              du stenger din nettleser. Vi benytter både permanente cookies og
              sesjonscookies til følgende formål:
              <span className="inner-margin">
                til å gi oss anonym statistikk om bruken av våre nettsider (
                Google Analytics )
              </span>
              <span className="inner-margin">
                til å gi nettsiden vår flerspråklig funksjonalitet.
              </span>
            </p>
            <p className="white-prewrap">
              Dersom du ikke ønsker at våre nettsider plasserer cookies på ditt
              utsyr, kan du skru av cookies i din nettleser. Dersom du skrur av
              cookies vil funksjonaliteten for våre nettsider reduseres.{" "}
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">
              11. Personalisering av opphold hos oss
            </p>
            <p className="white-prewrap">
              Comfort Hotell Viktoria ønsker å tilby våre kunder et best mulig
              hotellopphold tilpasset den enkelte gjests preferasnser og
              kjøpshistorikk.
            </p>
            <p className="white-prewrap">
              Vi vil, med grunnlag i berettigede interesser bruke opplysninger
              vi har mottatt fra deg om dine preferanser for rom (for eksempel
              «vindu mot fjorden» og dine kjøp hos oss (sparkling water fremfor
              still water) til å tilby deg et tilpasset opphold.
            </p>
            <p className="white-prewrap">
              Disse opplysningene samles inn basert på dine kjøp hos oss og de
              ønsker du ytrer til våre ansatte. Vi vil i tillegg noen ganger
              hente inn informasjon om deg fra åpne kilder som internett og
              sosiale medier, begrenset til informasjon relevant for å tilby deg
              et personalisert opphold hos oss.{" "}
            </p>
            <p className="white-prewrap">
              Disse opplysningene samles inn basert på dine kjøp hos oss og de
              ønsker du ytrer til våre ansatte. Vi vil i tillegg noen ganger
              hente inn informasjon om deg fra åpne kilder som internett og
              sosiale medier, begrenset til informasjon relevant for å tilby deg
              et personalisert opphold hos oss.{" "}
            </p>
            <p className="white-prewrap">
              Grunnlaget for dette er berettigede interesser. Opplysningene vil
              ikke bli levert ut til andre.Du kan reservere deg mot vår bruk av
              dine opplysninger for personalisering av ditt opphold. Dette kan
              du gjøre ved å sende en epost til co.floro@strawberry.no.
            </p>
            <p className="white-prewrap-m3"></p>
            <p className="white-prewrap">
              12. Endringer i personvernerklæring eller i behandling
            </p>
            <p className="white-prewrap">
              Vi jobber kontinuerlig med utvikling og forbedring av våre
              tjenester overfor våre kunder. Dette vil kunne endre måten eller
              omfanget av vår behandling av personopplysninger. Informasjonen vi
              gir gjennom denne personvernerklæringen vil derfor justeres og
              oppdateres med ujevne mellomrom. Vi vil dessuten endre
              personvernerklæringen når nye regler eller myndighetspraksis gjør
              det nødvendig.{" "}
            </p>
          </div>
        </div>
      </div>
      {/* ; */}
    </>
  );
};

export default Personvernvilkar;
